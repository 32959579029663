// TODO: safe to update import paths, and delete this component once it's been added to `/base`
// https://github.com/Shopify/ms-marketing-fed/issues/2632

import {useEffect, useState} from 'react';
import cn from 'classnames';

import type {ImageProps} from '@/components/base/elements/Image/Image';
import Image from '@/components/base/elements/Image/Image';
import type {WistiaVideoPlayerProps} from '@/components/shared/WistiaVideoPlayer/types';
import type {LinkProps} from '@/components/base/elements/Link/Link';
import Link from '@/components/base/elements/Link/Link';
import ButtonGroup, {
  type ButtonGroupProps,
} from '@/components/base/modules/ButtonGroup/ButtonGroup';
import type {
  HeadingGroupContentProps,
  HeadingGroupProps,
} from '@/components/base/modules/HeadingGroup/HeadingGroup';
import HeadingGroup from '@/components/base/modules/HeadingGroup/HeadingGroup';
import {
  cardStyles,
  progressStyles,
  slideStyles,
} from '@/components/base/modules/HorizontalSlideshow/styles';
import {useMediaQuery} from '@/hooks/useMediaQuery';
import {twMerge} from '@/stylesheets/twMerge';
import type {VideoImageProps} from '@/components/pages/shared/VideoImage/VideoImage';
import WistiaVideoPlayer from '@/components/shared/WistiaVideoPlayer';

export interface HorizontalSlideCardProps {
  className?: string;
  headingGroup?: HeadingGroupContentProps & {
    headingAs?: HeadingGroupProps['headingAs'];
  };
  image?: ImageProps;
  video?: WistiaVideoPlayerProps;
  link?: LinkProps;
  mobileImage?: ImageProps;
  mobileBgColor?: string;
  videoImage?: VideoImageProps;
  time?: number;
}

export interface HorizontalSlideshowProps {
  buttonGroup?: ButtonGroupProps;
  cards?: HorizontalSlideCardProps[];
  className?: string;
  cols?: number;
  mode?: 'light' | 'dark';
  progressBarClassName?: string;
  autoPlay?: boolean;
  stopAutoplayAfterClick?: boolean;
  hasWideMedia?: boolean;
}

export default function HorizontalSlideshow({
  buttonGroup,
  cards = [],
  className,
  cols = 3,
  mode = 'light',
  progressBarClassName,
  autoPlay = true,
  stopAutoplayAfterClick = false,
  hasWideMedia = false,
}: HorizontalSlideshowProps) {
  const [activeIndex, setActiveIndex] = useState(-1);
  const [hasBeenClicked, setHasBeenClicked] = useState(false);
  const TRANSITION_DURATION = 15000;

  const handleClick = (index: number) => {
    setActiveIndex(index);
    setHasBeenClicked(true);
  };

  useEffect(() => {
    setActiveIndex(0);
  }, []);

  useEffect(() => {
    if (!autoPlay || (stopAutoplayAfterClick && hasBeenClicked)) {
      return;
    }
    const timer = setTimeout(() => {
      setActiveIndex((activeIndex + 1) % cards?.length);
    }, getTimeoutDuration());

    return () => clearTimeout(timer);

    function getTimeoutDuration() {
      if (activeIndex < 0) {
        return 100;
      } else if (cards[activeIndex].time) {
        return cards[activeIndex].time;
      } else {
        return TRANSITION_DURATION;
      }
    }
  }, [
    activeIndex,
    cards?.length,
    autoPlay,
    cards,
    hasBeenClicked,
    stopAutoplayAfterClick,
  ]);

  const isMdBreakpoint = useMediaQuery('(min-width: 900px)') ?? true;

  const numOfCols = {
    default: cols,
    sm: cols * 2,
    md: cols * 3,
    innerSm: cols * 1.5,
  };

  return (
    <div className={twMerge('grid', className)} data-mode={mode}>
      <div
        className={cn('container relative', {
          'md:aspect-video': !hasWideMedia,
          'md:aspect-[21/9]': hasWideMedia,
        })}
      >
        {cards?.map((card, index) => (
          <div key={`card-${index}`}>
            {!isMdBreakpoint ? (
              <div
                className={cn('mb-10')}
                style={{backgroundColor: card?.mobileBgColor}}
              >
                {(card?.mobileImage?.src || card?.image?.src) && (
                  <div className="mb-6">
                    {/* @ts-ignore */}
                    <Image
                      {...((card?.mobileImage?.src && card?.mobileImage) ||
                        (card?.image?.src && card?.image))}
                      className="w-full h-auto"
                      aria-hidden={false}
                    />
                  </div>
                )}
                <div className={cn(card?.mobileBgColor && 'px-6 pb-6')}>
                  <HeadingGroup size="t6" mode={mode} {...card.headingGroup} />
                  {card.link && (
                    <Link mode={mode} {...card.link}>
                      {card.link.children}
                    </Link>
                  )}
                </div>
              </div>
            ) : (
              <div
                className={twMerge(
                  cn(
                    slideStyles({active: index === activeIndex}),
                    'absolute flex gap-y-0 top-0',
                    card?.className,
                    {
                      'aspect-video': !hasWideMedia,

                      'aspect-[21/9]': hasWideMedia,
                    },
                  ),
                )}
              >
                {(card?.image?.src || card?.image?.srcSet) && !card.video && (
                  <div>
                    <Image
                      {...card?.image}
                      aria-hidden={index !== activeIndex}
                    />
                  </div>
                )}
                {card.video && (
                  <WistiaVideoPlayer
                    {...card.video}
                    aspectRatio={hasWideMedia ? 'aspect-[21/9]' : '16/9'}
                    classNames={{
                      ...card.video.classNames,
                      playButton: 'hidden',
                    }}
                    externalPlayPause={index === activeIndex ? 'play' : 'pause'}
                    fitStrategy="cover"
                    image={{
                      srcSet: card?.image?.src,
                    }}
                  />
                )}
                <div className="md:hidden grid grid-cols-4 sm:grid-cols-8 md:grid-cols-12">
                  <div className="grid col-span-4 sm:col-span-6 md:col-span-12 gap-y-xl">
                    <div className="flex flex-wrap gap-x-4 gap-y-6">
                      {Array(cards?.length)
                        .fill(null)
                        .map((_, progressIndex) => (
                          <div
                            key={`progress-${progressIndex}`}
                            onClick={() => handleClick(progressIndex)}
                            className={twMerge(
                              cn(
                                progressStyles({
                                  active: progressIndex === activeIndex,
                                  mode,
                                }),
                                progressBarClassName,
                              ),
                            )}
                          />
                        ))}
                    </div>
                    <HeadingGroup
                      size="t6"
                      mode={mode}
                      {...card.headingGroup}
                    />
                    {card.link && (
                      <Link mode={mode} {...card.link}>
                        {card.link.children}
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
      <div
        className={`hidden md:grid container items-start grid-cols-${numOfCols.default} gap-x-gutter md:pt-xl`}
      >
        {cards?.map((card, index) => (
          <div
            key={`card-${index}`}
            className={twMerge(cn(card.className, 'grid gap-y-md'))}
          >
            <div
              className="group/card grid gap-y-xl cursor-pointer items-start"
              onClick={() => handleClick(index)}
            >
              <div
                className={twMerge(
                  cn(
                    progressStyles({
                      active: index === activeIndex,
                      mode,
                    }),
                    index === activeIndex
                      ? `before:!duration-[${TRANSITION_DURATION}ms]`
                      : `before:duration-100`,
                    progressBarClassName,
                    {'before:transition-none': !autoPlay},
                  ),
                )}
              />
              <div
                className={cardStyles({
                  isActive: index === activeIndex,
                })}
              >
                <HeadingGroup size="t5" mode={mode} {...card.headingGroup} />
                {card.link && (
                  <Link mode={mode} {...card.link}>
                    {card.link.children}
                  </Link>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
      {buttonGroup && (
        <div className="container">
          <ButtonGroup center={isMdBreakpoint} mode={mode} {...buttonGroup} />
        </div>
      )}
    </div>
  );
}
